import { TaxFormType, UsUploadTaxFormType } from "@trolley/common-frontend";
import {
  Box,
  Button,
  Checkbox,
  DatePicker,
  Divider,
  ExternalLink,
  FileDownload,
  Form,
  FormItem,
  Grid,
  Heading,
  Modal,
  Select,
  Text,
  UploadDragger,
} from "components";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { notifyError, notifySuccess } from "store/actions/notifications";
import { getTaxFormLabel, submitRecipientTaxForm, updateRecipientTaxForms, uploadRecipientTaxDocument } from "store/actions/recipientTaxForms";
import { TaxForm, UsUpload } from "store/actions/taxForms";
import { createFormData, omitMaskedValues, handleFormErrors } from "utils/helpers";
import OtherUploadForm from "./OtherUploadForm";
import W8UploadForm from "./W8UploadForm";
import W9UploadForm from "./W9UploadForm";

function isTaxForm(form: Partial<TaxForm> | undefined): form is TaxForm {
  return !!form?.id;
}

const TAX_FORM_REVISIONS: Omit<Record<UsUploadTaxFormType | TaxFormType, string[]>, "US:UPLOAD"> = {
  [TaxFormType.W9]: [
    "2024-03",
    "2018-10",
    "2017-11",
    "2014-12",
    "2013-08",
    "2011-12",
    "2007-10",
    "2005-11",
    "2004-10",
    "2003-01",
    "2002-01",
    "2000-12",
    "1999-11",
    "1996-12",
    "1994-03",
    "1993-12",
    "1990-04",
    "1988-12",
    "1987-12",
    "1984-07",
    "1983-10",
  ],
  [TaxFormType.W8BEN]: ["2021-10", "2017-07", "2014-02", "2006-02", "2000-12", "1998-10"],
  [TaxFormType.W8BENE]: ["2021-10", "2017-07", "2016-04", "2014-02"],
  [UsUploadTaxFormType.W8IMY]: ["2021-10", "2017-06", "2016-09", "2014-04", "2006-02", "2003-12", "2000-12", "1998-10"],
  [UsUploadTaxFormType.W8ECI]: ["2021-10", "2017-07", "2014-02", "2006-02", "2006-01", "2000-12", "1998-10"],
  [UsUploadTaxFormType.W8EXP]: ["2023-10", "2017-07", "2016-09", "2014-04", "2006-02", "2006-01", "2000-12", "1998-10"],
  [UsUploadTaxFormType.W4]: [
    "2018",
    "2017",
    "2016",
    "2015",
    "2014",
    "2013",
    "2012",
    "2011",
    "2010",
    "2009",
    "2008",
    "2007",
    "2006",
    "2005",
    "2004",
    "2003",
    "2002",
    "2001",
    "2000",
    "1999",
    "1998",
    "1997",
    "1996",
    "1995",
    "1994",
    "1993",
    "1992",
    "1990",
  ],
  [TaxFormType.F8233]: ["2018-09", "2009-03", "2001-12", "1996-10", "1993-04", "1987-10", "1985-12", "1984-12"],
};

const TAX_FORM_REVISIONS_KEYS = Object.keys(TAX_FORM_REVISIONS);

interface Props {
  recipientId?: string;
  taxForm: Partial<TaxForm> | undefined;
  onUpdate?(taxForm: TaxForm): void;
  onClose(e?: any): void;
}

export default function UploadTaxDocument(props: Props) {
  const { recipientId, taxForm, onUpdate, onClose } = props;
  const [busy, setBusy] = useState(false);
  const [form] = Form.useForm();
  const uploadKind = Form.useWatch(["data", "uploadKind"], form);
  const isW9 = uploadKind === TaxFormType.W9;
  const isW8 = [TaxFormType.W8BEN, TaxFormType.W8BENE].includes(uploadKind);

  useEffect(() => {
    if (taxForm) {
      setBusy(false);
      const initialValues = { ...taxForm, signedAt: taxForm.signedAt || dayjs().format("YYYY-MM-DD") };
      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
    }
  }, [taxForm]);

  async function onUpload(files: FormData) {
    if (recipientId) {
      setBusy(true);
      try {
        const newTaxForm = await uploadRecipientTaxDocument(recipientId, files);
        notifySuccess("Document uploaded");
        onUpdate?.(newTaxForm);
      } catch (errors) {
        notifyError("Document failed to upload", { errors });
      }
      setBusy(false);
    } else {
      notifyError("No recipient ID Provided");
    }
  }

  async function onFinish({ upload, terms, ...formValues }: any) {
    if (taxForm) {
      if (!isTaxForm(taxForm)) {
        await onUpload(createFormData([upload.file]));
      } else {
        const values = omitMaskedValues(formValues);

        setBusy(true);
        try {
          let signatoryName;
          switch (values.data.uploadKind) {
            case TaxFormType.W9:
              signatoryName = values.data.w9Data.name;
              break;
            case TaxFormType.W8BENE:
            case TaxFormType.W8BEN:
              if (!values.data.w8Data.name) {
                values.data.w8Data.name = values.data.w8Data.organizationName ?? [values.data.w8Data.firstName, values.data.w8Data.lastName].join(" ");
              }
              signatoryName = values.data.w8Data.name;
              break;
            default:
              signatoryName = values.data.organizationName ?? `${values.data.firstName} ${values.data.lastName}`;
              break;
          }

          await updateRecipientTaxForms(taxForm.recipientId, taxForm.id, values);

          if (taxForm.status === "incomplete") {
            await submitRecipientTaxForm(taxForm.recipientId, taxForm.id, signatoryName);
          }
          notifySuccess("Document saved");
          onClose();
        } catch (errors) {
          handleFormErrors(errors, form, "Saving document failed");
        }
        setBusy(false);
      }
    }
  }

  return (
    <Modal
      title="Upload Tax Document"
      confirmLoading={busy}
      okText={isTaxForm(taxForm) ? "Save Changes" : "Upload"}
      onOk={form.submit}
      onCancel={onClose}
      visible={!!taxForm}
      width={600}
    >
      {taxForm && (
        <Form form={form} requiredMark="optional" onFinish={onFinish}>
          {!isTaxForm(taxForm) ? (
            <Form.Item name="upload" rules={[{ required: true }]}>
              <UploadDragger
                maxCount={1}
                beforeUpload={() => {
                  return false;
                }}
              />
            </Form.Item>
          ) : (
            <>
              <Form.Item label="Uploaded Form" required>
                <FileDownload url={`/v1/recipients/${taxForm.recipientId}/tax/${taxForm.id}/download`} fileName={taxForm.id} icon button>
                  Download File
                </FileDownload>
              </Form.Item>
              <Grid padding={["medium", "none"]}>
                <Grid.Item xs={24} md={12}>
                  <FormItem label="Form Type" name={["data", "uploadKind"]} rules={[{ required: true, message: "Type of tax form is required" }]}>
                    <Select
                      onChange={() => {
                        form.setFieldsValue({
                          data: {
                            revision: undefined,
                          },
                        });
                      }}
                      placeholder="Select the tax form type"
                    >
                      {TAX_FORM_REVISIONS_KEYS.map((kind: keyof typeof TAX_FORM_REVISIONS) => (
                        <Select.Option key={kind} value={kind}>
                          {getTaxFormLabel(kind)}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Grid.Item>
                <Grid.Item xs={24} md={12}>
                  <FormItem
                    label="Form Revision"
                    tooltip={
                      <>
                        Please select the version of the tax form that was completed. This can usually be found in the top left corner of the form under the
                        form type, and is usually the month and/or year the form was updated.
                      </>
                    }
                    name={["data", "revision"]}
                    dependencies={[["data", "uploadKind"]]}
                    rules={[{ required: true, message: "Tax form revision is required" }]}
                  >
                    <Select placeholder={uploadKind}>
                      {TAX_FORM_REVISIONS[uploadKind]?.map((revision: string) => (
                        <Select.Option key={revision} value={revision}>
                          {dayjs(revision).format(revision.length > 4 ? "MMM YYYY" : "YYYY")}
                        </Select.Option>
                      ))}
                    </Select>
                  </FormItem>
                </Grid.Item>
              </Grid>

              <ExternalLink tabIndex={-1} href="https://www.irs.gov/pub/irs-utl/Tax_Treaty_Table_2.pdf">
                TABLE 3 - List of Tax Treaties
              </ExternalLink>
              <Divider margin="small" />

              {uploadKind && (
                <Grid padding={["medium", "none"]}>
                  <Grid.Item xs={24}>
                    <Heading tag="h2">Tax Form Information</Heading>
                    <Text type="secondary" size="small">
                      The following information should exactly match what is provided in the uploaded form and is needed for end-of-year tax reporting.
                    </Text>
                  </Grid.Item>

                  {isW9 && <W9UploadForm />}

                  {isW8 && <W8UploadForm />}

                  {!isW9 && !isW8 && <OtherUploadForm data={taxForm.data as UsUpload} />}

                  <Grid.Item xs={24}>
                    <Form.Item label="Date of Signature" name="signedAt" rules={[{ required: true, message: "Date of Signature is required" }]}>
                      <DatePicker type="past" />
                    </Form.Item>
                  </Grid.Item>
                </Grid>
              )}

              <Divider margin="small" transparent />
              <Box padding="xsmall" color="blue">
                <Form.Item
                  name="terms"
                  valuePropName="checked"
                  rules={[
                    {
                      async validator(rule, checked) {
                        if (!checked) {
                          throw "You must agree to the terms";
                        }
                      },
                    },
                  ]}
                  style={{ marginBottom: 0 }}
                >
                  <Checkbox>
                    I (the Merchant) acknowledge and accept that it is the Merchant's responsibility for ensuring the validity, including but not limited to the
                    accuracy, completeness and timeliness, of tax forms being uploaded to the Trolley platform from another system (i.e. 'System Change').
                    Trolley takes no responsibility for the accuracy and completeness of tax forms not completed on the Trolley platform.
                  </Checkbox>
                </Form.Item>
              </Box>
            </>
          )}
          <Button hidden htmlType="submit" />
        </Form>
      )}
    </Modal>
  );
}
